import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import BlogItem from "../components/BlogItem"
import BlogMainItem from "../components/BlogMainItem"
import styled from "styled-components"
import HomeBackground from "../components/Visuals/homeBackground"
import { MediaQueries } from "../utils/responsive"

const StyledContainer = styled(Container)`
  /* background: ${props => props.theme.colors.white}; */
  @media ${MediaQueries.desktop} {
    padding-top: 60px;
  }
`

const ArchivePage = ({ data, alternateLinks }) => {
  const mainItem = data.allSanityPost.edges[0]
  const otherItems = data.allSanityPost.edges.slice(1)

  const { i18n } = useTranslation("common")
  const language = i18n.language
  return (
    <Layout alternateLink={alternateLinks}>
      <Seo title="Blog" />
      <HomeBackground />
      <StyledContainer fluid>
        <Container>
          <Row>
            <Col xs={12}>
              <BlogMainItem item={mainItem.node} />
            </Col>
          </Row>
        </Container>
      </StyledContainer>

      <Container>
        <Row className="mb-5">
          {otherItems.map(edge => (
            <Col
              key={
                (Math.random() * Math.random()).toString() +
                edge.node._rawSlug[language]
              }
              xs={12}
              md={4}
              className="mt-5 pl-md-0"
            >
              <BlogItem item={edge.node} className="h-100" />
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArchivePageQuery {
    allSanityPost(
      sort: { fields: [highlighted, publishedAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          _rawSlug
          _rawTitle
          author {
            name
            image {
              asset {
                gatsbyImageData(layout: FIXED, height: 50, width: 50, fit: CROP)
              }
            }
          }
          _rawBody
          _rawSummary
          tags {
            _rawTitle
          }
          mainImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          highlighted
          publishedAt(formatString: "YYYY. MMMM D.", locale: "hu")
        }
      }
    }
  }
`

export default ArchivePage
