import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row, Col } from "reactstrap"
import Author from "../BlogItem/Author"
import { MediaQueries } from "../../utils/responsive"
import SanityBlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"

const StyledCard = styled(Row)`
  margin-bottom: 3rem;
  background: ${props => props.theme.colors.white};
  min-height: 300px;
  /* border-radius: 10px; */
  .card-image-holder {
    .card-image {
      height: 100%;
      /* border-radius: 5px; */
    }
  }
  .card-right-holder {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
  .card-date-holder {
    margin-top: 2rem;
  }
  .card-tags {
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 0.5rem;
    background: ${props => props.theme.colors.purple};
    border-radius: 1rem;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: inline-block;
    @media ${MediaQueries.mobile} {
      font-size: 1.2rem;
    }
  }
  .card-date {
    color: ${props => props.theme.colors.lightgray};
    font-size: 0.8rem;
    display: block;
    text-transform: uppercase;
    @media ${MediaQueries.mobile} {
      font-size: 1.2rem;
    }
  }
  .card-title {
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: bold;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    h1 {
      &:hover {
        color: ${props => props.theme.colors.pink};
      }
      @media ${MediaQueries.mobile} {
        font-size: 3.6rem;
      }

      color: ${props => props.theme.colors.purple};
    }
    p {
      margin-bottom: 0;
    }
  }
  .card-summary {
    margin-top: 2rem;
    p {
      color: ${props => props.theme.colors.darkgray};
    }
  }
`

const BlogMainItem = ({ item }) => {
  const { i18n } = useTranslation("common")
  const language = i18n.language

  return (
    <StyledCard>
      <Col
        xs={12}
        md={6}
        lg={5}
        className="px-0 pl-md-0 pr-md-3 card-image-holder"
      >
        <Link to={`/${language}/blog/${item._rawSlug[language]?.current}`}>
          <GatsbyImage
            className="card-image"
            image={getImage(item.mainImage.asset)}
            alt="Blog image"
          />
        </Link>
      </Col>
      <Col xs={12} md={6} lg={7} className="px-lg-0">
        <article className="card-right-holder">
          <div className="card-date-holder">
            {item.tags?.map(tag =>
              tag?._rawTitle ? (
                <span
                  className="card-tags"
                  key={(Math.random() * Math.random()).toString()}
                >
                  {tag?._rawTitle?.[language]}
                </span>
              ) : (
                ""
              )
            )}
            <time className="card-date">{` ${
              item.publishedAt || "Nincs dátum"
            }`}</time>
          </div>
          <div className="card-title">
            <Link to={`/${language}/blog/${item._rawSlug[language]?.current}`}>
              <h1>{item._rawTitle[language]}</h1>
            </Link>
          </div>
          <p className="card-summary">
            <SanityBlockContent
              blocks={item._rawSummary[language]}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              serializers={sanitySerializers}
              className="card-summary"
            />
          </p>
          <div className="card-author">
            <Author author={item.author} />
          </div>
        </article>
      </Col>
    </StyledCard>
  )
}

BlogMainItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default BlogMainItem
